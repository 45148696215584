//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { integerStyle, openTab } from '@/helpers/index'
import { firstTextHighlight } from '~/plugins/text-highlight'
import { marketListId } from '@/utils/marketlist'
const CONST_SECURITY_TYPE = {
  STOCK: 'S',
  UNITTRUST: 'U',
  ETF: 'L',
  DR: 'X',
  DRX: 'XF',
  DW: 'V',
  TFEX: 'TFEX', // (FUND, OPTION)
  FUND: 'FUND',
  OPTION: 'OPTION',
  FUTURE: 'FUTURE'
}
export default {
  name: 'SearchInput',
  props: {
    textPlaceholder1: {
      type: String,
      default: 'Get Quote'
    },
    textPlaceholder2: {
      type: String,
      default: 'ใส่ชื่อย่อหลักทรัพย์ (หุ้น, อนุพันธ์, กองทุนรวม)'
    },
    securityType: {
      type: String,
      default: '' // S (ALL) | U | L | X | XF | V | FUTURE | OPTION | FUND
    },
    securityTypeMulti: {
      type: Array,
      default: Array // ['', '', '', '', '']
    },
    isShowPopular: {
      type: Boolean,
      default: true
    },
    textNoResult: {
      type: String,
      default: 'ไม่พบชื่อย่อหลักทรัพย์ที่ค้นหา'
    }
  },
  data () {
    return {
      marketListId,
      showPlaceholder: true,
      keyword: '',
      active: false,
      listSearch: null,
      listSearchData: null,
      listPopular: [],
      recentQuote: [],
      // ready: false,
      CONST_SECURITY_TYPE
    }
  },
  computed: {
    ...mapGetters({
      stocks: 'search/getStocks',
      equityList: 'search/getEquityList',
      tfexList: 'search/getTFEXList',
      fundList: 'search/getFundList'
    }),
    checkSecurityTypeBlank () {
      return this.securityType === '' && this.securityTypeMulti.length === 0
    }
  },
  watch: {
    keyword () {
      if (this.keyword !== '' || !this.isShowPopular) {
        this.handleSearch(this.keyword)
      }
    }
  },
  mounted () {
    // this.ready = true
    // await this.fetchData()
    this.getRecentQuote()
    this.$nextTick(() => {
      this.registerEvent()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    integerStyle,
    firstTextHighlight,
    async fetchData () {
      if (!this.listSearchData || this.listSearchData.length === 0) {
        const stock = JSON.parse(JSON.stringify(this.equityList))
        const tfex = JSON.parse(JSON.stringify(this.tfexList))
        const funds = JSON.parse(JSON.stringify(this.fundList))
        let typeStock = []
        let typeUnitTrusts = []
        let typeETF = []
        let typeDR = []
        let typeDRX = []
        let typeDW = []
        let typeFuture = []
        let typeOption = []
        let typeFunds = []
        if (
          this.checkSecurityTypeBlank ||
          this.securityType === CONST_SECURITY_TYPE.STOCK ||
          this.securityTypeMulti.includes(CONST_SECURITY_TYPE.STOCK)
        ) {
          typeStock = stock
            .filter((data) => {
              return (
                data.securityType === 'S' ||
                data.securityType === 'F' ||
                data.securityType === 'P' ||
                data.securityType === 'Q' ||
                data.securityType === 'W'
              )
            })
            .map((data) => {
              return {
                ...data,
                type: 'หุ้น',
                url: `/equities/quote/${data.symbol}/overview`
              }
            })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.UNITTRUST)) {
          typeUnitTrusts = stock
            .filter((data) => {
              return data.securityType === 'U'
            })
            .map((data) => {
              return {
                ...data,
                type: 'หน่วยลงทุน',
                url: `/equities/quote/${data.symbol}/overview`
              }
            })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.ETF)) {
          typeETF = stock
            .filter((data) => {
              return data.securityType === 'L'
            })
            .map((data) => {
              return {
                ...data,
                type: 'ETF',
                url: `/equities/etf/quote/${data.symbol}/overview`
              }
            })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.DR)) {
          typeDR = stock
            .filter((data) => {
              return data.securityType === 'X'
            })
            .map((data) => {
              return {
                ...data,
                type: 'DR',
                url: `/equities/dr/quote/${data.symbol}/overview`
              }
            })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.DRX)) {
          typeDRX = stock
            .filter((data) => {
              return data.securityType === 'XF'
            })
            .map((data) => {
              return {
                ...data,
                type: 'DRx',
                url: `/equities/drx/quote/${data.symbol}/overview`
              }
            })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.DW)) {
          typeDW = stock
            .filter((data) => {
              return data.securityType === 'V'
            })
            .map((data) => {
              return {
                ...data,
                type: 'DW',
                url: `/equities/dw/quote/${data.symbol}/overview`
              }
            })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.OPTION) || this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.TFEX)) {
          typeFuture = tfex.filter((data) => {
            return data.marketListId !== marketListId.IO &&
            data.marketListId !== marketListId.TXI_O &&
            data.marketListId !== marketListId.TXI_C &&
            data.marketListId !== marketListId.TXI_P
          }).map((data) => {
            return {
              ...data,
              type: 'ฟิวเจอร์ส',
              url: `/derivatives/quote/${data.symbol}/overview`
            }
          })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.FUTURE) || this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.TFEX)) {
          typeOption = tfex.filter((data) => {
            return data.marketListId === marketListId.IO ||
            data.marketListId === marketListId.TXI_O ||
            data.marketListId === marketListId.TXI_C ||
            data.marketListId === marketListId.TXI_P
          }).map((data) => {
            return {
              ...data,
              type: 'ออปชัน',
              url: `/derivatives/quote/${data.symbol}/overview`
            }
          })
        }
        if (this.checkSecurityTypeIsHave(CONST_SECURITY_TYPE.FUND)) {
          typeFunds = funds.map((data) => {
            return {
              ...data,
              type: 'กองทุนรวม',
              url: `/mutualfund/quote/${encodeURIComponent(
                data.symbol
              )}/overview`
            }
          })
        }
        this.listSearchData = [
          ...typeStock,
          ...typeUnitTrusts,
          ...typeETF,
          ...typeDR,
          ...typeDRX,
          ...typeDW,
          ...typeFuture,
          ...typeOption,
          ...typeFunds
        ]
        // await this.$axios
        //   .get('api/set/stock/list')
        //   .then((res) => {
        //     this.listSearchData = res.data.securitySymbols
        //   })
        //   .catch(err => err)
        if (this.isShowPopular) {
          await this.getPopularList()
        } else {
          this.handleSearch(this.keyword)
        }
      }
    },
    Highlight (content) {
      return this.firstTextHighlight(this.keyword, content)
    },
    registerEvent () {
      this.$refs.searchInput.addEventListener(
        'blur',
        this.handleBlurInputSearch
      )
      this.$refs.searchInput.addEventListener(
        'focus',
        this.handleFocusInputSearch
      )
    },
    removeEvent () {
      this.$refs.searchInput.removeEventListener(
        'blur',
        this.handleBlurInputSearch
      )
      this.$refs.searchInput.removeEventListener(
        'focus',
        this.handleFocusInputSearch
      )
    },
    handleFocusInputSearch () {
      this.showPlaceholder = false
      this.$refs.searchInput.focus()
      this.active = true
      this.fetchData()
    },
    handleBlurInputSearch () {
      this.showPlaceholder = !this.keyword
      // this.active = false
    },
    handleSearch (keyword) {
      const tempKeyword = keyword.replace(/\s/g, '').toUpperCase()
      if (tempKeyword !== '') {
        this.listSearch = this.listSearchData.filter(item =>
          item.symbol.replace(/\s/g, '').toUpperCase().startsWith(tempKeyword)
        )
      } else {
        this.listSearch = this.listSearchData
      }
      // if (!this.listSearch.length) {
      //   this.listSearch = this.listSearchData
      // }
    },
    async handleSelectQuote ($event, item) {
      const symbol = item.symbol
      let securityType = item.securityType
      $event.stopPropagation()
      $event.preventDefault()
      let tempQuote = await this.recentQuote
      const quoteNow = []
      if (symbol) {
        quoteNow.push(symbol)
      }
      if (tempQuote.length) {
        if (quoteNow.length) {
          const isSame = tempQuote.filter(q => q === quoteNow[0])
          if (isSame.length) {
            const index = tempQuote.indexOf(isSame[0])
            if (index > -1) {
              tempQuote.splice(index, 1)
            }
            tempQuote = [...quoteNow, ...tempQuote]
            tempQuote.splice(5, 1)
          } else {
            tempQuote = [...quoteNow, ...tempQuote]
            tempQuote.splice(5, 1)
          }
        }
        this.$cookies.set('recent-quote', JSON.stringify(tempQuote))
      } else {
        this.$cookies.set('recent-quote', JSON.stringify(quoteNow))
      }
      if (!securityType) {
        const itemFromListSearch = this.stocks.filter((data) => {
          return data.symbol === symbol
        })
        securityType = itemFromListSearch[0]?.securityType ?? ''
      }
      const urlString = this.$quote.getQuoteUrl({
        symbol,
        securityType
      })
      openTab(urlString, '_blank')
      this.getRecentQuote()
      this.active = false
    },
    handleOutsideClick () {
      this.active = false
    },
    getRecentQuote () {
      if (this.$cookies.get('recent-quote')) {
        this.recentQuote = this.$cookies.get('recent-quote')
      } else {
        this.recentQuote = []
      }
    },
    moveActiveItemSearchResultWithArrow (key) {
      const activeItem = document.querySelector('.currsor-active')
      if (activeItem !== null) {
        const currentIndex = Number(activeItem.id.split('_')[1])
        const lastIndex = this.listSearch.length - 1
        if (key.keyCode === 38 && currentIndex > 0) {
          // ArrowUp
          activeItem.classList.remove('currsor-active')
          const nextIndex = currentIndex - 1
          const nextItem = document.getElementById(
            `search-list-item_${nextIndex}`
          )
          nextItem.classList.add('currsor-active')
          document.querySelector('.search').scrollTop = 0
          document.querySelector('.search').scrollTop =
            nextItem.offsetTop - document.querySelector('.search').offsetHeight
        } else if (key.keyCode === 40 && currentIndex < lastIndex) {
          // ArrowDown
          activeItem.classList.remove('currsor-active')
          const nextIndex = currentIndex + 1
          const nextItem = document.getElementById(
            `search-list-item_${nextIndex}`
          )
          nextItem.classList.add('currsor-active')
          document.querySelector('.search').scrollTop = 0
          document.querySelector('.search').scrollTop =
            nextItem.offsetTop - document.querySelector('.search').offsetHeight
        } else if (key.keyCode === 13) {
          // Enter
          const dataSet = JSON.parse(JSON.stringify(activeItem.dataset))
          this.clickEventQuote(key, {
            symbol: dataSet.value,
            securityType: dataSet.type
          })
        }
      }
    },
    async getPopularList () {
      let securityType = ''
      if (!this.securityType || this.securityType === '') {
        securityType = CONST_SECURITY_TYPE.STOCK
      } else {
        securityType = this.securityType
      }
      if (this.securityTypeMulti && this.securityTypeMulti.length) {
        for (const itemType of this.securityTypeMulti) {
          const listPopular = await this.$apiCms
            .get(
              `api/cms/v1/popularquote/getpopular?market=SET&securityType=${itemType}&limit=5`
            )
            .then((res) => {
              if (res && res.data.length) {
                let popular = res.data
                popular = popular.map((item, index) => {
                  const checkIsQuote = this.listSearchData.find(
                    q =>
                      item.symbol &&
                      q.symbol &&
                      q.symbol.replace(/\s/g, '').toUpperCase() ===
                        item.symbol.replace(/\s/g, '').toUpperCase()
                  )
                  if (checkIsQuote) {
                    return {
                      ...item,
                      type: checkIsQuote.type,
                      securityType: itemType,
                      company: checkIsQuote.nameTH
                    }
                  } else {
                    return {
                      ...item,
                      type: '',
                      securityType: itemType,
                      company: ''
                    }
                  }
                })
                return popular
              }
            })
          if (listPopular) {
            this.listPopular = [...this.listPopular, ...listPopular]
          }
        }
      } else {
        this.listPopular = await this.$apiCms
          .get(
            `api/cms/v1/popularquote/getpopular?market=SET&securityType=${securityType}&limit=5`
          )
          .then((res) => {
            if (res && res.data.length) {
              let popular = res.data
              popular = popular.map((item, index) => {
                const checkIsQuote = this.listSearchData.find(
                  q =>
                    item.symbol &&
                    q.symbol &&
                    q.symbol.replace(/\s/g, '').toUpperCase() ===
                      item.symbol.replace(/\s/g, '').toUpperCase()
                )
                if (checkIsQuote) {
                  return {
                    ...item,
                    type: checkIsQuote.type,
                    securityType,
                    company: checkIsQuote.nameTH
                  }
                } else {
                  return {
                    ...item,
                    type: '',
                    securityType,
                    company: ''
                  }
                }
              })
              return popular
            }
          })
      }
    },
    checkTypeIsStock (securityType) {
      return (
        securityType === '' ||
        securityType === CONST_SECURITY_TYPE.STOCK ||
        securityType === CONST_SECURITY_TYPE.UNITTRUST ||
        securityType === CONST_SECURITY_TYPE.ETF ||
        securityType === CONST_SECURITY_TYPE.DR ||
        securityType === CONST_SECURITY_TYPE.DRX ||
        securityType === CONST_SECURITY_TYPE.DW
      )
    },
    checkTypeIsFund (securityType) {
      return securityType === '' || securityType === CONST_SECURITY_TYPE.FUND
    },
    checkTypeIsTFEX (securityType) {
      return (
        securityType === '' ||
        securityType === CONST_SECURITY_TYPE.TFEX ||
        securityType === CONST_SECURITY_TYPE.OPTION ||
        securityType === CONST_SECURITY_TYPE.FUTURE
      )
    },
    clickEventQuotePopular (event, item) {
      this.$personalized.clickElement({
        name: 'all18',
        optionLang: {
          symbol: item.symbol
        }
      })
      this.handleSelectQuote(event, item)
    },
    clickEventQuote (event, item) {
      this.$personalized.clickElement({
        name: 'all19',
        optionLang: {
          symbol: item.symbol
        }
      })
      this.handleSelectQuote(event, item)
    },
    clickEventQuoteRecently (event, symbol) {
      this.$personalized.clickElement({
        name: 'all20',
        optionLang: {
          symbol
        }
      })
      this.handleSelectQuote(event, { symbol })
    },
    checkSecurityTypeIsHave (type) {
      return (
        this.checkSecurityTypeBlank ||
        this.securityType === type ||
        this.securityTypeMulti.includes(type)
      )
    }
  }
}
